@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");
@import "../variables.scss";


.App {
  /* text-align: center; */
  margin: auto;
  align-items: center;
  margin-top: px(62);
}

@media screen and (max-width: 550px) {
  .App {
    display: contents;
  }
}

@media (max-width: 991px) {
  .App {
    text-align: center;
  }
}

div.ant-modal-footer {
  border-top: 0;
  padding: 0;
}

button.ant-btn-primary[disabled] {
  background: #939393;
  border-radius: 5px;
  color: #FFFFFF;
}

button.ant-btn-primary {
  background: $blue;
}

.d-none {
  display: none;
}

.click {
  cursor: pointer;
}

.circle {
  border-radius: 99999px;
  width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}