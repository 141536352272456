@import "../../variables.scss";

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  box-shadow: 0px px(2) px(4) rgba(0, 0, 0, 0.08);
  background: #ffffff;

  .logo {
    // padding: px(20) px(20);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: px(20);

    img {
      max-height: px(26);
    }
  }

  .search {
    max-width: px(750);
    width: 50%;
    height: px(45);
    justify-content: center;
    align-items: center;
  }

  .btn-login {
    cursor: pointer;
    margin: 0 px(20);

    img {
      width: px(40);
      height: px(40);
    }
  }

  .userHeader {
    display: flex;
    justify-content: center;

    .title {
      align-self: center;
      margin-right: px(24);
    }
  }

  @media screen and (max-width: 991px) {
    .logo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: px(12);
    }

    .btn-login {
      margin: px(10) 0;

      button.strokeBlueBtn {
        height: px(30);

        span {
          margin: 0;
        }
      }

      .title {
        display: none;
      }

      img {
        height: px(32);
        width: px(32);
      }
    }
  }
}

.nameUser {
  font-style: normal;
  font-weight: 400;
  font-size: px(20);
  line-height: px(23);
}

.infoMenu {
  .item {
    padding: px(12) px(16);
    border-bottom: 1px solid #e5e5e5;
  }

  .finally {
    border-width: 0;
  }

  .infoUserWrapper {
    display: flex;

    .userImage {
      height: px(50);
      width: px(50);
      border-radius: px(50/2);
    }

    .infoUser {
      display: flex;
      flex-direction: column;
      margin-left: px(20);
    }

    .email {
      margin-top: px(8);
      font-size: px(16);
      line-height: px(19);
    }
  }

  .pointWrapper {
    .point {
      color: $blue;
      font-size: px(20);
      line-height: px(23);
    }
  }

  .sp-24 {
    margin-bottom: px(12);
  }

  .item-click {
    cursor: pointer;
    align-content: center;
    align-items: center;
    display: flex;

    .click-title {
      margin-left: px(12);
    }
  }
}

.userInfoModal {
  .ant-modal-body {
    padding: px(10);

    .item {
      padding: px(12) px(10);

      &.pointWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: nowrap;
      }
    }
  }
}
