@import "../../../variables.scss";


.pointModal {

  .ant-form-item-control-input {
    min-height: 0;
  }

  .ant-form-item-required {
    color: #000000;
  }

  .ant-modal-content {
    width: 100%;
    max-width: px(820);
  }

  .mobile {
    width: 100%;
  }


  .title {
    color: #1F91FA;
    font-weight: 600;
    font-size: 18px;
  }

  .item-full {
    label {
      width: 100%;
    }
  }

  .subTitle {
    font-size: px(15);
    line-height: 18px;
    color: $gray;
    margin-bottom: px(35);
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .quantity {
    text-align: center;

    .ant-input-number-group-addon {
      border: 0;
    }

    input.ant-input-number-input {
      text-align: center;
    }
  }

  .ant-input-number-group-addon {
    padding: 0;
    margin: 0;
  }

  .quantityInput {

    .ant-form-item-control-input-content {
      display: flex;
    }
  }

  .quantityBtn {
    display: flex;
    align-items: center;
  }

  .ant-form-item-required {
    width: 100%;

    .money {
      display: flex;
      width: 100%;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      align-content: center;

      .maxMoney {
        color: $blue;
      }
    }
  }

  .products {
    position: relative;
    cursor: pointer;

    .productItem {
      width: px(138);
      margin-right: px(20);

      &.active {
        .productImage {
          border: 2px solid #1F91FA;
          border-radius: 5px;
        }

      }

      .productImage {
        background: rgba(255, 255, 255, 0.0001);
        border: 1px solid #E5E5E5;
        border-radius: 5px;
        padding: px(6);
        position: relative;

        img {
          width: 100%;
          border-radius: 2px;
        }

        .priceWrapper {
          width: 100%;
          position: absolute;

          left: 0;
          bottom: 11px;

          .price {
            background: rgba(33, 33, 33, 0.5);
            border-radius: 2px;
            margin: 0 px(15);
            text-align: center;
            font-weight: 500;
            color: $primaryWhite;
          }
        }
      }

      .productName {
        width: 100%;
        text-align: center;
        min-height: px(36);
        font-size: px(16);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: #1A0303;
      }
    }

    .scrollIcon {
      top: px(65);
    }
  }

  .item-voucher {
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12));
    border-radius: 10px;
    min-height: px(80);
  }

  @media screen and (max-width: 991px) {
    .ant-modal-content {
      width: 100%;

      .ant-modal-body {
        padding: 0;
        max-width: 100%;

        overflow-y: auto;
        max-height: calc(100vh);

        .order {
          padding: px(24);

        }

        .ant-form-item-label {
          color: #030303;
        }
      }
    }

  }
}