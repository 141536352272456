@import "../../variables.scss";

.pointMessage {
  transform: scale(0);
  transition-duration: 0.3s;

  &.visible {
    transform: scale(1);
    color: $secondaryColor;
  }

  &.success {
    color: $primaryColor;
  }
}

button.strokeBlueBtn {
  height: px(45);
  background-color: #F8F8F8;
  border: 1px solid $strokeBlue;
  color: $strokeBlue;
  font-size: px(18);
  line-height: px(121);
  padding: 0 px(20);
  /* identical to box height */

  display: flex;
  align-items: center;
  border-radius: 2px;

  span {
    margin-left: px(12);
  }

  &:hover {
    background-color: #DEF1FF;
  }
}

.mainBtn {
  background: $blue;
  border-radius: px(5);

  &:hover {
    background: #40A9FF;
  }

  &:active {
    background: #1C62B9;
  }
}

button.cancelBtn {
  background: rgba(255, 255, 255, 0.0001);
  /* Default */

  border: 1px solid #1F91FA;
  border-radius: 10px;
  color: #1F91FA;

  min-width: px(120);

  &:hover {
    color: #ffffff;
  }
}