@import "../../variables.scss";


.footer {
  width: 100%;
  text-align: center;
  padding: px(8);
  font-size: px(16);
  line-height: px(19);
  background-color: white;
  color: #606060;
}