@import '../../variables.scss';

.hozScroll {
  position: relative;

  .hozScrollItemWrapper {
    margin-right: px(20);
    min-width: px(356);
    height: 100%;
  }
}

.react-horizontal-scrolling-menu--scroll-container {
  overflow-y: hidden;
  /* Hide vertical scrollbar */
  overflow-x: hidden;
  /* Hide horizontal scrollbar */
}

@media screen and (max-width: 991px) {}

.scrollIcon {
  position: absolute;
  cursor: pointer;
  background: #F9F9F9;
  transition: all 200ms;
  top: px(100);
  width: px(50);
  height: px(50);
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  img {
    // width: 100%;
    max-width: px(20);
    max-height: px(20);
  }

  &:hover {
    background: #E5E5E5;
  }

  &:active {
    background: #CCCCCC;
  }
}

.iconLeft {
  left: 0;
}

.iconRight {
  right: 0;
}