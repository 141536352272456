.menuMobile {
  height: 100%;

  div.ant-modal-content {
    height: 100%;
    background: transparent;
    border: 0;

    .ant-modal-body {
      padding: 0;
      height: 100%;
    }
  }
}