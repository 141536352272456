@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F5F5;
}

html {
  font-size: calc((var(--base) / 16) * 100%);
  --base: 16;
}

#root {
  overflow-x: hidden;
}