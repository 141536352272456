@import "../../../../variables.scss";

.videoWrapper {
  .videoContainer {
    display: grid;
    width: 100%;
    grid-template-columns: 70% 30%;
  }

  .playerWrapper {
    position: relative;
    padding-top: 45%;
    width: 100%;

    .reactPlayer {
      position: absolute;
      top: 0;
      left: 0;
    }

    .playerButtonOverlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 50px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      .playPauseIcon {
        height: 100px;
        opacity: 0;
        transition: 0.3s;
      }

      &:hover {
        .playPauseIcon {
          opacity: 1;
        }
      }
    }
  }

  .infoVideoMobile {
    padding-top: px(12);
    margin-bottom: px(20);

    .logoChannelMobile {
      width: px(38);
      height: px(38);
      margin: 0 px(12);
    }

    .videoName {
      text-align: left;
      font-weight: 600;
      font-size: 15px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .channelName {
      text-align: left;
    }
  }


  .videoNoteReward {
    font-size: 16px;
    color: #606060;
    font-weight: 400;
    margin-top: px(10);

    .highlights {
      color: $blue;
      font-weight: 700;
      margin: auto 6px;
    }
  }

  .answerKeyword {
    color: $blue;
    font-weight: 600;
    font-size: 18px;
    margin: auto 0;
    width: 100%;
  }

  .controlWrapper {
    text-align: left;
    margin: auto;
    width: 100%;
  }

  .controlBtn {
    display: flex;
    justify-content: space-around;
    text-align: center;

    .controlBtnItem {
      color: $primaryColor;
      padding: 5px 5px;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.2s;
      position: relative;

      &:hover {
        opacity: 1;
      }

      img {
        height: 80px;
      }

      .label {
        color: #1f91fa;
        margin-top: 5px;
      }
    }
  }

  .shareWrapper {
    border-radius: 4px;
    font-size: 13px;
    height: 28px;
    padding: 5px 10px;
    color: white;
    display: flex;
    align-items: center;
    width: fit-content;
  }

  .videoNameWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: px(30) 0;

    .videoName {
      color: $primaryBlack;
      font-size: 18px;
      font-weight: 600;
      font-size: 22px;
      margin-bottom: 0;
      margin-right: px(30);
    }
  }

  .promotion {
    padding-left: px(30);

    .banner {

      &.exchangePoints {
        display: flex;
        flex-direction: column;
        background: #F2F2F2;
        text-align: center;

        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
        border-radius: 20px;

        .exchangeImageWrapper {}

        .exchangeImage {
          max-width: 100%;
          margin: 0 auto;
          border-radius: 8px;
        }

        .points {
          font-weight: 600;
          font-size: px(18);
          color: #1F91FA;
          margin: 0 px(10);
        }

        .timeBox {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          background: #FFFFFF;
          width: 100%;
          border-radius: 0px 0px 20px 20px;
          padding: px(20) px(10);
          gap: px(15);

          .countdownTime {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $gray;
          }

          .pointBtn {
            border-radius: px(100);
          }

          .time {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            align-items: center;
            padding: 0px;
            font-size: px(16);
            width: px(40);
            height: px(40);

            margin: 0 px(4);

            background: #E2EFFC;
            color: #1C62B9;
            border: 1px solid #1C62B9;
            border-radius: 2px;
          }
        }
      }

      .bannerImage {
        width: 100%;

        &.constant-tilt-shake {
          animation: tilt-shaking 0.3s infinite;
        }
      }
    }

    .boxDeepview {
      margin-top: px(30);
      padding: px(30);
      text-align: center;

      background: #FFFFFF;

      box-shadow: 0px 0px px(4) rgba(0, 0, 0, 0.16);
      border-radius: px(20);

      .content {
        color: #1F91FA;
        font-weight: 600;
        font-size: 18px;
      }
    }
  }

  .infoChannel {
    align-items: center;

    .channelLogo {
      width: px(50);
      height: px(50);
      border-radius: px(50 /2);
    }

    .channelName {
      margin-left: px(20);
      font-size: px(20);
    }
  }

  .receivePrizeBtn {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 15px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    display: flex;
    justify-content: center;

    &:hover {
      background-color: rgba(255, 255, 255, 0.9);
    }
  }

  @media screen and (max-width: 991px) {
    .videoContainer {
      display: flex;
      flex-direction: column;
      width: 100%;

      .promotion {
        padding: px(12);
      }
    }

    .boxDeepViewMobile {
      padding: px(12);

      .boxDeepview {
        display: flex;
        background-color: $primaryWhite;
        box-shadow: 1px 2px 4px rgba(33, 33, 33, 0.24);
        border-radius: 20px;
        padding: px(16);

        .content {
          text-align: left;
          margin-right: px(12);
        }

        .controlBtnItem {
          display: flex;
          align-items: center;

          .pointMessage {
            width: 0;
            display: block;
          }

          img {
            width: px(80);
            height: px(80);
            max-width: none;
          }
        }
      }
    }

    .noteMobile {
      margin: px(12);
    }

    .boxPointMobile {
      background: #F9F9F9;
    }

    .playerWrapper {
      padding-top: 56.25%;
      // aspect-ratio: 377/270;
      max-height: px(270);
    }

    .shareWrapper {
      justify-content: center;
    }

    .controlBtn {
      .controlBtnItem {
        img {
          height: 50px;
        }
      }
    }
  }
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(2deg);
  }

  50% {
    transform: rotate(0eg);
  }

  75% {
    transform: rotate(-2deg);
  }

  100% {
    transform: rotate(0deg);
  }
}