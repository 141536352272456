@import "../../variables.scss";

.homePageContainer {
  .searchBar {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      display: flex;

      .lite {
        color: #1f91fa;
        padding-left: 15px;
        margin-left: 15px;
        border-left: 1px solid #58afff;
        line-height: 26px;
        font-size: 16px;
      }
    }

    .searchInput {
      width: 60%;
      background-color: white;
      border-radius: 4px;

      .ant-input-search-button {
        border-radius: 4px !important;
      }
    }

    @media screen and (max-width: 550px) {
      .logo {
        display: none;
      }

      .searchInput {
        width: 100%;
      }
    }
  }

  .sortBar {
    margin: 20px 0;
    text-align: right;

    .sortSelect {
      width: 120px;
      margin-left: 10px;
      background-color: white;
      text-align: center;

      .ant-select-selector {
        border-radius: 4px;
      }
    }
  }

  .paging {
    margin: 50px 0;
    text-align: center;
  }

  .products {
    width: 100%;

    .iconRight,
    .iconLeft {
      top: 0;
    }
  }

  .infinite-scroll-component__outerdiv {
    height: 100%;
  }
}