$primaryColor: #006ED2;
$primaryBlack: #000000;
$primaryWhite: #ffffff;
$secondaryColor: #ff4d4f;
$bgGrey: #F8F8F8;
$blue: #1F91FA;
$strokeBlue: #1C62B9;
$gray: #606060;
$btnHover: #F0F0F0;

// px to rem
$base: 16;

@function px($inputPx: 0) {
  @return ($inputPx / $base * 1rem)
}