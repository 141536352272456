@import "../../../variables.scss";

div.purchaseVideoModal {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  text-align: center;

  .purchaseVideoWrapper {
    padding: px(10);
  }

  .context {
    margin-top: px(40);
    margin-bottom: px(20);
    display: flex;

    .icon {
      margin-right: px(10);
    }
  }

  .closeBtn {
    border-radius: 5px;
    min-width: px(120);
    margin-bottom: px(10);
  }
}